/*
Project specific variables
*/

$colour-dark-cyan: rgb(21, 143, 168);
$colour-midnight-blue: rgb(6, 31, 57);
$colour-red: rgb(220, 72, 73);

$colour-gray: rgb(221,221,221);


$navbar-height: 56px;
$schedule-time-height: 48px;
$schedule-location-position: $navbar-height + $schedule-time-height;
$schedule-location-height: 48px;
$schedule-talk-position: $schedule-location-position + $schedule-location-height;
