/*
Root SCSS file for this project
*/

@import 'scss/fonts/work_sans';
@import 'scss/variables';
@import 'scss/bootstrap/pre_default';
@import 'scss/bootstrap/config';

// Custom components
@import 'scss/components/navbar_address';
@import 'scss/components/nav_link';
@import 'scss/components/front_carousel';
@import 'scss/components/post_mid_title';
@import 'scss/components/speaker_snippet';
@import 'scss/components/mailing_banner';
@import 'scss/components/sponsor_overview';
@import 'scss/components/empty_paras';
@import 'scss/components/org_team';

// Page specific customizations
@import 'scss/views/landing_page';
@import 'scss/views/schedule_pages';
