/*
The carousel banner images
*/
.banner-pycon {
    background-image: url('/images/pycon_graphic_1600_700.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 350px;
}

.banner-city {
    background-image: url('/images/toronto_city_1600_700.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 350px;
}

/*
The logo
*/
.banner-pycon-logo {
    width: 120px;

    @include media-breakpoint-up(sm) {
        width: 25%;
    }

    @include media-breakpoint-up(md) {
        width: 20%;
    }

    @include media-breakpoint-up(lg) {
        width: 10%;
    }
}

.city-banner {
    font-variant: small-caps;
}

.city-heading {
    color: map-get($pycon-colours, 'red');
}

/*
Carousel wrapping color
*/
.banner-wrapper {
    // Can't decide if this color is good or not ... ?!?!
    background-color: map-get($pycon-colours, 'midnight-blue');
}

/*
Allow the carousel to be full width for large and below
ref: https://stackoverflow.com/a/46360823/198660
*/
@include media-breakpoint-down(lg) {
    .banner-container {
        width: 100%;
        max-width: none;
    }
}

.pycon-buy-button {

    @include media-breakpoint-up(sm) {
        font-size: 1.1rem;
    }

    @include media-breakpoint-up(md) {
        font-size: 1.5rem;
    }

    @include media-breakpoint-up(lg) {
        font-size: 2.0rem;
    }
}