/*
We want the font a bit reduced for the top-menu links
*/

.nav-link {
    // font-size: .75em;
    // font-weight: bold;
}

@include media-breakpoint-up(xl) {
    .nav-link {
        // font-size: 1em;
        // font-weight: bold;
    }
}

.navigation-button {
    text-transform: uppercase;
    font-size: 1.0rem;
    border-radius: 70px;
    margin-top: 0.75rem;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;

    @include media-breakpoint-up(lg) {
        // back to defaults
        margin-top: 0;
        padding: 0.375rem 0.75rem;
    }

    &:before, &:after {
        content: "\00a0";
    }


}

a.nav-link, .nav-mobile-sublink {
    border-bottom: 1px solid map-get($pycon-colours, 'red');

    @include media-breakpoint-up(lg) {
        border-bottom: none;
    }
}

.nav-mobile-icon {
    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.nav-mobile-submenu {
    border: none;
    @include media-breakpoint-up(lg) {
        border: 1px solid rgba(0, 0, 0, 0.15);
    }
}

.dropdown-menu {
    padding: 0;
    margin: 0;
    @include media-breakpoint-up(lg) {
        padding: 0.5rem 0;
        margin: 0.125rem 0 0;
    }
}

.nav-mobile-sublink {
    padding: 0.5rem 1rem;

    @include media-breakpoint-up(lg) {
        padding: 0.25rem 1.5rem;
    }
}