/*
Customizations specific to the landing page
*/

@each $colour, $value in $pycon-colours {
    @include bg-variant('.bg-#{$colour}', $value);
  
    @include text-emphasis-variant('.text-#{$colour}', $value);
  
    .btn-#{$colour} {
      @include button-variant($value, $value);
    }
  
    .btn-outline-#{$colour} {
      @include button-outline-variant($value, $value);
    }
}
  
.btn-round {
  border-radius: 15px;
  text-transform: uppercase;

  &.btn-lg {
    border-radius: 25px;
  }

  &.btn-xl {
    border-radius: 35px;
  }
}

.bg-graphic {
  background-image: url('/images/2018_PyCon_Main_graphic_1920x1920.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.spacer {
  height: 4rem;
}

  
