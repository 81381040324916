// ref: https://google-webfonts-helper.herokuapp.com/fonts/work-sans?subsets=latin,latin-ext

/* work-sans-100 - latin-ext_latin */
@font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 100;
    src: url('../fonts/work-sans-v3-latin-ext_latin-100.eot'); /* IE9 Compat Modes */
    src: local('Work Sans Thin'), local('WorkSans-Thin'),
         url('../fonts/work-sans-v3-latin-ext_latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/work-sans-v3-latin-ext_latin-100.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/work-sans-v3-latin-ext_latin-100.woff') format('woff'), /* Modern Browsers */
         url('../fonts/work-sans-v3-latin-ext_latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/work-sans-v3-latin-ext_latin-100.svg#WorkSans') format('svg'); /* Legacy iOS */
  }
  /* work-sans-200 - latin-ext_latin */
  @font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 200;
    src: url('../fonts/work-sans-v3-latin-ext_latin-200.eot'); /* IE9 Compat Modes */
    src: local('Work Sans ExtraLight'), local('WorkSans-ExtraLight'),
         url('../fonts/work-sans-v3-latin-ext_latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/work-sans-v3-latin-ext_latin-200.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/work-sans-v3-latin-ext_latin-200.woff') format('woff'), /* Modern Browsers */
         url('../fonts/work-sans-v3-latin-ext_latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/work-sans-v3-latin-ext_latin-200.svg#WorkSans') format('svg'); /* Legacy iOS */
  }
  /* work-sans-300 - latin-ext_latin */
  @font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/work-sans-v3-latin-ext_latin-300.eot'); /* IE9 Compat Modes */
    src: local('Work Sans Light'), local('WorkSans-Light'),
         url('../fonts/work-sans-v3-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/work-sans-v3-latin-ext_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/work-sans-v3-latin-ext_latin-300.woff') format('woff'), /* Modern Browsers */
         url('../fonts/work-sans-v3-latin-ext_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/work-sans-v3-latin-ext_latin-300.svg#WorkSans') format('svg'); /* Legacy iOS */
  }
  /* work-sans-regular - latin-ext_latin */
  @font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/work-sans-v3-latin-ext_latin-400.eot'); /* IE9 Compat Modes */
    src: local('Work Sans'), local('WorkSans-Regular'),
         url('../fonts/work-sans-v3-latin-ext_latin-400.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/work-sans-v3-latin-ext_latin-400.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/work-sans-v3-latin-ext_latin-400.woff') format('woff'), /* Modern Browsers */
         url('../fonts/work-sans-v3-latin-ext_latin-400.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/work-sans-v3-latin-ext_latin-400.svg#WorkSans') format('svg'); /* Legacy iOS */
  }
  /* work-sans-500 - latin-ext_latin */
  @font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/work-sans-v3-latin-ext_latin-500.eot'); /* IE9 Compat Modes */
    src: local('Work Sans Medium'), local('WorkSans-Medium'),
         url('../fonts/work-sans-v3-latin-ext_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/work-sans-v3-latin-ext_latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/work-sans-v3-latin-ext_latin-500.woff') format('woff'), /* Modern Browsers */
         url('../fonts/work-sans-v3-latin-ext_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/work-sans-v3-latin-ext_latin-500.svg#WorkSans') format('svg'); /* Legacy iOS */
  }
  /* work-sans-600 - latin-ext_latin */
  @font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/work-sans-v3-latin-ext_latin-600.eot'); /* IE9 Compat Modes */
    src: local('Work Sans SemiBold'), local('WorkSans-SemiBold'),
         url('../fonts/work-sans-v3-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/work-sans-v3-latin-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/work-sans-v3-latin-ext_latin-600.woff') format('woff'), /* Modern Browsers */
         url('../fonts/work-sans-v3-latin-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/work-sans-v3-latin-ext_latin-600.svg#WorkSans') format('svg'); /* Legacy iOS */
  }
  /* work-sans-700 - latin-ext_latin */
  @font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/work-sans-v3-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
    src: local('Work Sans Bold'), local('WorkSans-Bold'),
         url('../fonts/work-sans-v3-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/work-sans-v3-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/work-sans-v3-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
         url('../fonts/work-sans-v3-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/work-sans-v3-latin-ext_latin-700.svg#WorkSans') format('svg'); /* Legacy iOS */
  }
  /* work-sans-800 - latin-ext_latin */
  @font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 800;
    src: url('../fonts/work-sans-v3-latin-ext_latin-800.eot'); /* IE9 Compat Modes */
    src: local('Work Sans ExtraBold'), local('WorkSans-ExtraBold'),
         url('../fonts/work-sans-v3-latin-ext_latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/work-sans-v3-latin-ext_latin-800.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/work-sans-v3-latin-ext_latin-800.woff') format('woff'), /* Modern Browsers */
         url('../fonts/work-sans-v3-latin-ext_latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/work-sans-v3-latin-ext_latin-800.svg#WorkSans') format('svg'); /* Legacy iOS */
  }
  /* work-sans-900 - latin-ext_latin */
  @font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/work-sans-v3-latin-ext_latin-900.eot'); /* IE9 Compat Modes */
    src: local('Work Sans Black'), local('WorkSans-Black'),
         url('../fonts/work-sans-v3-latin-ext_latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/work-sans-v3-latin-ext_latin-900.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/work-sans-v3-latin-ext_latin-900.woff') format('woff'), /* Modern Browsers */
         url('../fonts/work-sans-v3-latin-ext_latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/work-sans-v3-latin-ext_latin-900.svg#WorkSans') format('svg'); /* Legacy iOS */
  }